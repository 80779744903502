<template>
  <div class="myAssResult">
    <!-- 我的考核结果 -->
    <van-nav-bar :title="$t('myAssResult.resTxt1')"
                 left-text
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="content">
      <van-tabs v-model="activeName"
                color="#2B8DF0"
                title-active-color="#2B8DF0"
                @change="changeAct">
        <van-tab v-if="$t('myAssResult.resTxt2')"
                 :title="$t('myAssResult.resTxt2')"
                 name="360Tag"></van-tab>
        <van-tab v-if="$t('myAssResult.resTxt3')"
                 :title="$t('myAssResult.resTxt3')"
                 name="KPITag"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoadingAwait"
                        @refresh="onRefreshAwait">
        <van-list v-model="loadingAwait"
                  :finished="finishedAwait"
                  :finished-text="$t('module.noMore')"
                  @load="onLoadAwait">
          <div class="zb_group"
               v-for="(item, index) in assessList"
               :key="index">
            <van-cell v-if="activeName == '360Tag'"
                      class="ass_cell"
                      size="large"
                      center
                      :title="item.khtimename ? item.khtimename : ''"
                      :label="item.khname"
                      is-link
                      @click="goToApply(item)" />
            <van-cell v-else
                      class="ass_cell"
                      size="large"
                      center
                      :title="item.khtimename"
                      :label="item.planname"
                      is-link
                      :value="(item.lastscore ? item.lastscore : 0) + $t('myAssResult.resTxt5')"
                      @click="goToApply(item)">
              <template #default>
                <van-tag plain
                         v-if="item.canss && item.sstype == '按结果申诉' && !item.isss"
                         type="primary"
                         size="medium"
                         style="margin-right: 10px"
                         :color="'#2b8df0'"
                         @click.stop="applyAppeal(item)">{{$t('myAssResult.resTxt4')}}</van-tag>
                <span class="custom-title">{{ item.lastscore ? item.lastscore : 0 }}{{$t('myAssResult.resTxt5')}}{{ item.khres?'('+item.khres+')':'' }}</span>
              </template>
            </van-cell>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getMy360HisRecList, getMyKpiHisRecList } from "@api/wxjx.js";
export default {
  data () {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {};
    return {
      userInfo,
      activeName: "360Tag",
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      assessList: [],
    };
  },
  created () { },
  beforeRouteEnter (to, from, next) {
    let is360 = true;
    if (
      from.name == "jxMyAssResultsList" ||
      from.name == "jxMyAssResultsAppeal"
    )
    {
      is360 = from.query.is360;
    }
    next((vm) => {
      //因为当钩子执行前，组件实例还没被创建
      // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
      if (is360 == true || is360 == "true")
      {
        vm.activeName = "360Tag";
      } else
      {
        vm.activeName = "KPITag";
      }
    });
  },
  methods: {
    onClickLeft () {
      this.$router.push("/home");
    },
    // 申诉按钮
    applyAppeal (item) {
      let is360 = this.activeName == "360Tag";
      this.$router.push({
        path: "/jxMyAssResultsAppeal",
        query: {
          is360: is360,
          mautoid: item.autoid,
          lastscore: item.lastscore,
          khres: item.khres,
          khtimename: item.khtimename,
          planname: item.planname,
          sstype: item.sstype,
        },
      });
    },
    /// 8.1.获取我的历史360考核方案列表
    getMy360His () {
      let _this = this;
      getMy360HisRecList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    // 8.2.获取我的历史KPI考核方案列表
    getMyKpiHis () {
      let _this = this;
      getMyKpiHisRecList({ empid: this.userInfo.empid }).then((res) => {
        if (res.data.length == 0)
        {
          _this.finishedAwait = true;
          // 无数据
        } else
        {
          _this.assessList = res.data;
          _this.loadingAwait = false;
          _this.finishedAwait = true;
        }
      });
    },
    onLoadAwait () {
      // 异步更新数据
      if (this.isLoadingAwait)
      {
        this.assessList = [];
        this.isLoadingAwait = false;
      }
      if (this.activeName == "360Tag")
      {
        this.getMy360His();
      } else
      {
        this.getMyKpiHis();
      }
    },
    onRefreshAwait () {
      this.assessList = [];
      this.finishedAwait = false;
      this.loadingAwait = true;
      this.onLoadAwait();
      Toast(this.$t('module.refreshSuccessfully'));
    },
    // tab切换事件
    changeAct (name, title) {
      this.onRefreshAwait();
    },
    // 发起指标申请
    goToApply (item) {
      console.log(item, 'goToApply')
      let is360 = true;
      let is_jjp = null;
      let is_zjp = null;
      let is_zp = null;

      let is_khcheck = null
      let is_send_khcheck = null
      const mautoid = item.autoid;
      if (this.activeName == "360Tag")
      {
        is360 = true;
      } else
      {
        is360 = false;
        is_jjp = item.is_jjp;
        is_zjp = item.is_zjp;
        is_zp = item.is_zp;
        is_khcheck = item.is_khcheck
        is_send_khcheck = item.is_send_khcheck
      }
      // console.log(item,'1111111111')
      let obj = {
        title: item.khtimename,
        khname: item.planname,
        is360: is360,
        mautoid: mautoid,
        lastscore: item.lastscore,
        is_jjp: is_jjp,
        is_zjp: is_zjp,
        is_zp: is_zp,
        sjtype: item.sstype,
        cansj: item.canss,
        khres: item.khres,
        is_khcheck: is_khcheck,
        is_send_khcheck: is_send_khcheck
      }
      let str = JSON.stringify(obj);
      window.sessionStorage.setItem("goToApplyObj", str);
      this.$router.push({
        path: "/jxMyAssResultsList",
        query: obj
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myAssResult {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 100px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 100px);
    }

    .zb_group {
      // .van-cell::after {
      //   position: absolute;
      //   box-sizing: border-box;
      //   content: " ";
      //   pointer-events: none;
      //   right: 0.42667rem;
      //   bottom: 0;
      //   left: 0.42667rem;
      //   border-bottom: 2px solid #ebedf0;
      //   -webkit-transform: scaleY(0.5);
      //   transform: scaleY(0.5);
      // }
      .ass_cell {
        margin: 10px 0;
        .van-cell__title {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
        .van-cell__value {
          color: #2b8df0;
          font-weight: bold !important;
        }
      }
    }
  }
}
</style>